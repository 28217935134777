import React, {useCallback, useState} from 'react';
import {useDropzone} from 'react-dropzone';

function InputDropZone(props) {

    const [files, setFiles] = useState([]);

    const onDrop = useCallback((acceptedFiles) => {


    //   // Create a new FormData object
    // const formData = new FormData();
    
    // // Append each accepted file to the FormData object
    // acceptedFiles.forEach((file) => {
    //   formData.append('files', file);
    // });

      const updatedFiles = acceptedFiles.map((file) => ({
        file:file,
        id: Math.random().toString(),
        preview: window.URL.createObjectURL(file) 
      }));
      
      // preview: (window.URL) ? window.URL.createObjectURL(file) : window.webkitURL.createObjectURL(file)
      setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);

      props.setDisplayFilesInfos(true);
    }, []);
    

    const removeFile = (e, id) => {
      e.stopPropagation();
      setFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop });


  return (
    
        <div className="dropzone" {...getRootProps()}>

            <div style={{
                backgroundColor:"white",
                color:"gray",
                padding: 10,
                width: "80%",
                borderRadius:10,
                margin: "5vh auto"
            }}>
                <input {...getInputProps()} />
                <p>Drag and drop  PDF</p> 
                <p>  or Image</p>
            </div>

            {
                props.displayFilesInfos && 
                <p style={{padding: 18}}>
                
                {files.map((file) => (
            
                    <div key={file.id} className="file-item">
                        <span>{file.file.name}</span>
                        <button onClick={(e) => removeFile(e, file.id)}>Clear</button>
                    </div>

                    ))}

                {files.length > 0  && <button onClick={(e) => {

                    e.stopPropagation();
                    props.onFileSubmit(files);
                    setFiles([]);
                
                }} className="process" >Upload</button>}

                </p>
        }

    </div>

  );
}

export default InputDropZone;