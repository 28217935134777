import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faEye } from '@fortawesome/free-solid-svg-icons';
import { Flex, notification } from 'antd';
import axios from 'axios';
import './MessageBubble.css'


const MessageBubble = ({ voted, index, uuid, form_submitted, content, sender, onUpdateMessage }) => {

	// Create the Axios instance
	const axiosInstance = axios.create();
	axiosInstance.interceptors.request.use((config) => {
		const token = localStorage.getItem('token');
		// console.log(token)
		config.headers.Authorization = token ? `Bearer ${token}` : '';
		return config;
	});


	const [vote, setVote] = useState(voted); // 'up' for thumbs up, 'down' for thumbs down, or null for neither

	const isUpActive = vote === 'up';
	const isDownActive = vote === 'down';



	const handleVote = async (data) => {
		// Handle upvote action
		console.log('Upvote clicked', data.direction);

		if (data.uuid === '') {
			notification.warning({
				message: 'Feedback',
				description:
					'Thanks, The feedback con not be processed!',
				// onClick: () => {
				//   console.log('Notification Clicked!');
				// },
			});
		} else if (vote) { // to prevent multiple feedbacks
			console.log(vote)
			notification.warning({
				message: 'Feedback',
				description:
					'Thanks, Your previous feedback has already been saved!',
			});
		} else {

			try {
				const response = await axiosInstance.put(`${window.location.protocol}//${window.location.hostname}/api/v1/message/${uuid}`,
					{ 'correct_predict': data.direction });

				const botResponse = response.data.message;
				console.log(botResponse)
				// Check for a new token in the Authorization header
				const newToken = response.headers['authorization'] || response.headers['Authorization'];
				if (newToken) {
					// Extract the token value if it's in the format "Bearer token_value"
					const tokenValue = newToken.split(' ')[1];
					if (tokenValue) {
						// Update the token in local storage
						localStorage.setItem('token', tokenValue);
					}
				}
				setVote(data.direction); // Toggle the vote on click, allowing de-selection
				// setVote(vote === data.direction ? null : data.direction); // Toggle the vote on click, allowing de-selection

				onUpdateMessage(index, { voted: data.direction }) // update the message

				//  send notification
				notification.info({ message: 'Feedback', description: 'Thank you for providing your feedback!' });
			} catch (error) {

				notification.error({ message: 'Feedback', description: 'Oops, something went wrong while updating this message.' });

			}

		}

	};


	// begin  form handling     
	const [groundTruth, setGroundTruth] = useState('');
	const [nodeId, setNodeId] = useState('');
	const [isSubmittable, setIsSubmittable] = useState(false);
	const [formIsSubmitted, setFormIsSubmitted] = useState(form_submitted);

	// Check if at least one field has a value and update the submittable state accordingly
	const checkSubmittable = (groundTruthValue, nodeIdValue) => {
		if (groundTruthValue && nodeIdValue) {
			setIsSubmittable(true);
		} else {
			setIsSubmittable(false);
		}
	};

	const handleGroundTruthChange = (e) => {
		const value = e.target.value;
		setGroundTruth(value);
		checkSubmittable(value, nodeId);
	};

	const handleNodeIdChange = (e) => {
		const value = e.target.value;
		setNodeId(value);
		checkSubmittable(groundTruth, value);
	};

	const handleSubmit = async (event) => {
		event.preventDefault(); // Prevent the default form submit action
		console.log(event.form)
		if (!isSubmittable) return; // Do nothing if the form is not submittable
		if (uuid === '') {
			//  send notification
			notification.warning({ message: 'Feedback', description: 'Thanks, this form can be processed.' });
			return;
		}
		// Check if at least one field is filled
		if (groundTruth || nodeId) {
			try {
				// Replace 'your-endpoint-url' with the actual endpoint you wish to consume
				const response = await axiosInstance.put(`${window.location.protocol}//${window.location.hostname}/api/v1/message/${uuid}`,
					{ 'ground_truth': groundTruth, 'node_page_id': nodeId });

				const botResponse = response.data.message;
				console.log(botResponse)
				// Check for a new token in the Authorization header
				const newToken = response.headers['authorization'] || response.headers['Authorization'];
				if (newToken) {
					// Extract the token value if it's in the format "Bearer token_value"
					const tokenValue = newToken.split(' ')[1];
					if (tokenValue) {
						// Update the token in local storage
						localStorage.setItem('token', tokenValue);
					}
				}

				setFormIsSubmitted(true) // set the form is submitted 

				onUpdateMessage(index, { form_submitted: true }) // update the message

				// setVote(vote === data.direction ? null : data.direction); // Toggle the vote on click, allowing de-selection

				//  send notification
				notification.info({ message: 'Feedback', description: 'Thank you for filling the form!' });
			} catch (error) {

				notification.error({ message: 'Feedback', description: 'Oops, something went wrong when submitting the form.' });

			}
		} else {
			console.log('At least one field should be filled');

			notification.warning({
				message: 'Feedback',
				description:
					'At least one field should be filled'
			});

			// Provide user feedback
		}
	};

	// end form handling 


	// Input styles based on the isSubmittable state
	const inputStyle = {
		borderColor: isSubmittable ? 'initial' : 'red',
	};



	function renderQuestion() {

		return (
			<div className='question'>
				<div className="item_container">
					<div className="value">{content}</div>
					<div className='logo_container'>
						<img className='logo_item' alt="logo_question" src="https://www.freepnglogos.com/uploads/question-mark-png/file-lol-question-mark-wikimedia-commons-19.png" />
					</div>
				</div>
			</div>
		)
	}

	function renderAnswer() {

		return (
			<div className='answer'>
				<div className="item_container">
					<div className='logo_container'>
						{/* <img className='logo_item' alt="logo_question" src="https://www.freepnglogos.com/uploads/question-mark-png/file-lol-question-mark-wikimedia-commons-19.png"/> */}
						<img className='logo_item' alt="logo_question" src="ai-icon.png" />
						{/* <img className='logo_item' alt="logo_question" src="metis_logo.png"/>  */}
					</div>
					<div className="value">
						{content}
						{uuid &&
							<div className='feedback'>
								<Flex gap="middle" justify='space-between'>
									<div className='icons'>

										<button
											onClick={() => handleVote({ direction: 'up', 'uuid': uuid })}
											className={`buttonStyle thumbsUpStyle icon-button ${isUpActive ? 'active' : ''}`}
										>
											<FontAwesomeIcon size='2x' icon={faThumbsUp} color={isUpActive ? 'green' : 'grey'} />
										</button>
										<button
											onClick={() => handleVote({ direction: 'down', 'uuid': uuid })}
											className={`buttonStyle thumbsDownStyle icon-button ${isDownActive ? 'active' : ''}`}
										>
											<FontAwesomeIcon size='2x' icon={faThumbsDown} color={isDownActive ? 'red' : 'grey'} />
										</button>
									</div>
									{!formIsSubmitted && (isDownActive || isUpActive) && (
										<form onSubmit={handleSubmit}>
											<input
												type="text"
												placeholder="Type the ground truth..."
												value={groundTruth}
												onChange={handleGroundTruthChange}
												style={inputStyle}
											/>
											<input
												type="text"
												placeholder="Type the node or page id..."
												value={nodeId}
												onChange={handleNodeIdChange}
												style={inputStyle}
											/>
											<input
												name='uuid'
												type="hidden"
												value={uuid} // Make sure uuid is defined in your component's state
											/>
											<button type="submit" disabled={!isSubmittable}>Send</button>
										</form>
									)

									}

								</Flex>
							</div>

						}

					</div>

				</div>
			</div>
		)
	}

	return (
		<div className='item'>
			{
				eval(`render${sender}()`)
			}
		</div>
	)
};

export default MessageBubble;
