import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Login.css'; // Make sure you have your CSS file
import logo from './images/logo-color.svg'; // Adjust the path to your logo 
import qs from 'qs';  // You may need to install qs with npm install qs
//require('dotenv').config();
import {notification } from 'antd';

const Login = ({ onLoginSuccess }) => {

const [isLoggedIn, setIsLoggedIn] = useState(false);

const BACKEND_HOST = process.env.BACKEND_HOST || window.location.hostname;
// const BACKEND_PORT = process.env.BACKEND_PORT || "5000";

//Get the current page protocol
const PROTOCOL = window.location.protocol;


// const BACKEND_HOST = process.env.BACKEND_HOST || "chat.fleuron.ai";
// const PROTOCOL = 'https:';

const url = `${PROTOCOL}//${BACKEND_HOST}/api/login`;
// const url = `${PROTOCOL}://${BACKEND_HOST}:${BACKEND_PORT}/api/login`;
// console.log(process.env)

useEffect(() => {
    document.title = 'Login | Fleuron';
    }, []);




  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [errors, setErrors] = useState({});
  const [loginError, setLoginError] = useState('');

  const validateForm = () => {
    let isValid = true;
    let errors = {};

    if (!credentials.username) {
      errors.username = 'Username is required';
      isValid = false;
    }

    if (!credentials.password) {
      errors.password = 'Password is required';
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      try {
        
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        const data = qs.stringify(credentials);
   
        const response = await axios.post(url, data,config);

        // Handle login success
        // console.log('Login successful:', response.data);
        // You can store the received token in localStorage/sessionStorage or context
        // Store the received token
        localStorage.setItem('token', response.data.access_token);

        // Redirect to App.js page or another page
        // Method 1: Using React Router
        // this.props.history.push('/Chat');

        // Method 2: Simple window redirection
        // window.location.href = '/Chat';

        // Call onLoginSuccess to switch to the Chat component
        onLoginSuccess();

        notification.success({
          message: 'Login',
          description:
            'Login successfully',
          // onClick: () => {
          //   console.log('Notification Clicked!');
          // },
        });



      } catch (error) {
        // Handle error case
        notification.error({
          message: 'Login',
          description:
            'Login failed',
          // onClick: () => {
          //   console.log('Notification Clicked!');
          // },
        });

        setLoginError(error.response?.data?.message || 'Login failed');
      }
    }
  };

  const handleChange = (event) => {
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  return (
    <div className="main-body">
            <div className="login-container">
            <img src={logo} alt="Logo" className="login-logo" />
        <form onSubmit={handleSubmit}>
            <h2>Login</h2>
            {/* Input fields */}
            <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
                type="text"
                id="username"
                name="username"
                value={credentials.username}
                onChange={handleChange}
            />
            {errors.username && <div className="error">{errors.username}</div>}
            </div>
            <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
                type="password"
                id="password"
                name="password"
                value={credentials.password}
                onChange={handleChange}
            />
            {errors.password && <div className="error">{errors.password}</div>}
            </div>
            {loginError && <div className="error">{loginError}</div>}
            <div className="form-group">
                <button type="submit" className="login-submit-button">Login</button>
            </div>
            
        </form>
        </div>
    </div>
    
  );
};

export default Login;
