// // src/App.js
// import React from 'react';
// import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
// import Chat from './Chat'; // Import your Chat component
// import Login from './Login';

// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Login />} />
//         <Route path="/chat" element={<Chat />} />
//         {/* Add other routes as needed */}
//       </Routes>
//     </Router>
//   );
// }

// export default App;

import React, { useState } from 'react';
import Login from './Login'; // Import your Login component
import Chat from './Chat'; // Import your Chat (main content) component

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(
    () => JSON.parse(localStorage.getItem('isLoggedIn')) || false
  );

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.setItem('isLoggedIn', 'false');
  };

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
  };

  return (
    <div>
      {isLoggedIn ? (
        <Chat  onLogout={handleLogout} />
      ) : (
        <Login onLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  );
}

export default App;
