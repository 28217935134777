import React, { useState } from 'react';

const InputField = ({ onSendMessage }) => {
  const [message, setMessage] = useState('');
    
  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim() !== '') {
      onSendMessage(message);
      setMessage('');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="input-container">
        <input type="text" value={message} onChange={handleChange} placeholder="Type your document related query..." />
        <button type="submit">Send</button>
    </form>
  );
};

export default InputField;
