import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ChatContainer from './components/ChatContainer';
import InputField from './components/InputField';
import InputDropZone from './components/InputDropZone';
import logo from './images/logo.svg';
import './Chat.css';
import ImageViewer from './components/ImageViewer';
import { Flex, Spin, notification } from 'antd';

import { Document, Page, pdfjs } from 'react-pdf';

// import Gallery from 'react-image-gallery';
// import 'react-image-gallery/styles/css/image-gallery.css'; // Import the CSS

// import ImageViewer from 'react-image-viewer';
// // import 'react-image-viewer/style.css'; // Import the CSS
// import './ImageViewer.css'; // Import your custom CSS

// import injectTapEventPlugin from 'react-tap-event-plugin';

// // Initialize the tap event plugin
// injectTapEventPlugin();

// const images = [
//   {
//     original: 'image1.jpg',
//     thumbnail: 'thumbnail1.jpg',
//     description: 'Image 1 Description',
//   },
//   {
//     original: 'image2.jpg',
//     thumbnail: 'thumbnail2.jpg',
//     description: 'Image 2 Description',
//   },
//   // Add more images here
// ];


// Install dotenv using npm or yarn
// const dotenv = require('dotenv');
// dotenv.config(); // Load environment variables from .env file

// Initialize PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const useLocalStorageState = (key, initialValue) => {
	// Try to load the saved value from localStorage, or use the initial value
	const [value, setValue] = useState(() => {
		try {
			const item = window.localStorage.getItem(key);
			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			console.log(error);
			return initialValue;
		}
	});

	// Use useEffect to update localStorage when the state changes
	useEffect(() => {
		try {
			const valueToStore = value instanceof Function ? value(value) : value;
			window.localStorage.setItem(key, JSON.stringify(valueToStore));
		} catch (error) {
			console.log(error);
		}
	}, [key, value]);

	return [value, setValue];
};


const Chat = ({ onLogout }) => {
	// Create the Axios instance
	const axiosInstance = axios.create();
	axiosInstance.interceptors.request.use((config) => {
		const token = localStorage.getItem('token');
		// console.log(token)
		config.headers.Authorization = token ? `Bearer ${token}` : '';
		return config;
	});

	// Call this function when the user chooses to log out
	const logout = () => {
		// alert("The session has expired!")

		notification.info({
			message: 'Session',
			description:
				"The session has expired!",
			// onClick: () => {
			//   console.log('Notification Clicked!');
			// },
		});

		onLogout();
		// Additional logout logic if needed
	};

	useEffect(() => {
		document.title = 'Chat | Fleuron';

	}, []);



	// Assuming images is an array of image URLs
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	const nextImage = () => {
		setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
	};

	const prevImage = () => {
		setCurrentImageIndex((prevIndex) =>
			prevIndex === 0 ? images.length - 1 : prevIndex - 1
		);
	};

	// const [messages, setMessages] = useState([]);
	const [messages, setMessages] = useLocalStorageState('messages', []);

	// varaible to update Mesage  in a child component
	const updateMessage = (index, newProps) => {
		const updatedMessages = [...messages];
		updatedMessages[index] = { ...updatedMessages[index], ...newProps };
		setMessages(updatedMessages);
	};


	const [metadata, setMetadata] = useState({});
	const [displayFilesInfos, setDisplayFilesInfos] = useState(true);

	const [loading, setLoading] = useState(false);

	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	// const [pdfUrl, setPdfUrl] = useState(null);
	// // Use the useState hook to create a state variable for images
	// const [images, setImages] = useState();

	const [uploadedFileMetadata, setUploadedFileMetadata] = useLocalStorageState('uploadedFileMetadata', null);

	// Use the custom hook for pdfUrl
	const [pdfUrl, setPdfUrl] = useLocalStorageState('pdfUrl', null);
	// Use the custom hook for images
	const [images, setImages] = useLocalStorageState('images', []);

	// const images = [
	//   './images/background.png',
	//   // ... more images
	// ];

	const BACKEND_HOST = process.env.BACKEND_HOST || window.location.hostname;
	// Get the current page protocol
	const PROTOCOL = window.location.protocol;

	// const BACKEND_HOST = process.env.BACKEND_HOST || "chat.fleuron.ai";
	// const PROTOCOL = 'https:';
	// const BACKEND_PORT = process.env.BACKEND_PORT || "5000";


	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};


	const sendMessage = async (message) => {
		// Use axiosInstance to send the POST request
		// console.log({message,'filename':sessionStorage.getItem('currentFilename')})
		try {
			const currentFile = sessionStorage.getItem('currentFilename')
			if (currentFile) {

				const response = await axiosInstance.post(`${PROTOCOL}//${BACKEND_HOST}/api/v1/get_bot_response`, { message, 'filename': currentFile });

				const botResponse = response.data.response;
				const uuidResponse = response.data?.uuid ?? '';
				console.log(uuidResponse)
				// Check for a new token in the Authorization header
				const newToken = response.headers['authorization'] || response.headers['Authorization'];
				if (newToken) {
					// Extract the token value if it's in the format "Bearer token_value"
					const tokenValue = newToken.split(' ')[1];
					if (tokenValue) {
						// Update the token in local storage
						localStorage.setItem('token', tokenValue);
					}
				}

				setMessages((prevMessages) => [
					...prevMessages,
					{ content: message, sender: 'Question', uuid: uuidResponse },
					{ content: botResponse, sender: 'Answer', uuid: uuidResponse, form_submitted: false, voted: false },
				]);

			} else {
				// alert("Upload a file or wait for processing to finish.")
				notification.warning({
					message: 'Message',
					description:
						"Upload a file or wait for processing to finish.",
					// onClick: () => {
					//   console.log('Notification Clicked!');
					// },
				});
			}
		} catch (error) {
			console.error('Error in sending message:', error);
			notification.error({
				message: 'Message',
				description: 'Oops! Something went wrong. Try reloading the files and see if that fixes it.',
				// onClick: () => {
				//   console.log('Notification Clicked!');
				// },
			});

			// console.log(error.response.status)
			// Check if the error is a 401 Unauthorized response
			if (error.response && error.response.status === 401) {
				// Call the onLogout function if a 401 Unauthorized response was received
				logout();
			} else {
				const botResponse = error.response.data.response;
				setMessages((prevMessages) => [
					...prevMessages,
					{ content: message, sender: 'Question', uuid: '' },
					{ content: 'Oops! Something went wrong. Try reloading the files and see if that fixes it.', sender: 'Answer', uuid: '', form_submitted: false, voted: false },
				]);

			}

			// Handle other errors as needed
		}
	};
	// convert file to base64
	function convertFileToBase64(file, callback) {
		const reader = new FileReader();
		reader.readAsDataURL(file.file); // Converts the file to Base64
		reader.onload = () => callback(reader.result); // Callback with Base64 result
		reader.onerror = (error) => console.error('Error converting file to Base64:', error);
	}


	const submitFilesAndProcess = (files) => {
		// alert('Processing PDF file. Please wait...');

		setLoading(true); // Start loading
		notification.info({
			message: 'File',
			description:
				'Processing PDF file. Please wait...',
			// onClick: () => {
			//   console.log('Notification Clicked!');
			// },
		});
		// Prepare form data for API call
		const formData = new FormData();
		files.forEach((file) => {
			formData.append('files', file.file);

			if (file.file instanceof Blob) {
				convertFileToBase64(file, (base64String) => {
					const uploadedFile = {
						type: file.file.type,
						src: file.file.path,
						alt: file.file.name,
						file: base64String,
						preview: file.preview
					};

					// Store uploaded file metadata in local storage and update state
					localStorage.setItem('uploadedFileMetadata', JSON.stringify(uploadedFile));
					setUploadedFileMetadata(JSON.stringify(uploadedFile));
					// console.log('local storage', localStorage.getItem('uploadedFileMetadata'));

					// Handle PDF files separately from images
					if (file.file.type === 'application/pdf') {
						setPdfUrl(uploadedFile);
						setImages(null);
					} else {
						setImages(uploadedFile);
						setPdfUrl(null);
					}
				});
			} else {
				console.error('Invalid PDF Blob');
				notification.error({
					message: 'File',
					description:
						'Invalid PDF Blob',
					// onClick: () => {
					//   console.log('Notification Clicked!');
					// },
				});

			}
		});
		// const token = localStorage.getItem('token');
		// console.log(token)
		// Make API call to upload files
		axiosInstance.post(`${PROTOCOL}//${BACKEND_HOST}/api/v1/upload`, formData, {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
			.then((response) => {
				// alert('File uploaded successfully');
				const uploadedFile = {
					currentFilename: response.data.filename,
					uploadedFile: JSON.parse(localStorage.getItem('uploadedFileMetadata'))
				};

				// Clear messages and store upload metadata in session storage
				const lastUploadedFile = JSON.parse(sessionStorage.getItem(sessionStorage.getItem('currentFilename')));

				if (!lastUploadedFile || lastUploadedFile.currentFilename !== response.data.filename) {
					setMessages([]);
				}

				// remove the old vale before setting
				// sessionStorage.removeItem(response.data.filename);
				// sessionStorage.removeItem('currentFilename');
				sessionStorage.clear();

				sessionStorage.setItem(response.data.filename, JSON.stringify(uploadedFile));
				sessionStorage.setItem('currentFilename', response.data.filename);

				notification.success({
					message: 'File',
					description:
						'File Uploaded successfully!',
					duration: 30
				});
			})
			.catch((error) => {
				// alert('Error uploading file');

				notification.error({
					message: 'File',
					description:
						'Error uploading file.',
					duration: 30
				});

				console.error('Error uploading file', error);

				// Attempt to recover from error by checking sessionStorage
				const lastUploadedFile = JSON.parse(sessionStorage.getItem(sessionStorage.getItem('currentFilename')));
				if (lastUploadedFile) {
					const uploadedFile = JSON.parse(lastUploadedFile.uploadedFile);
					if (uploadedFile.type === 'application/pdf') {
						setPdfUrl(uploadedFile);
						setImages(null);
					} else {
						setImages(uploadedFile);
						setPdfUrl(null);
					}
				} else {
					setPdfUrl(null);
					setImages(null);
				}

				// Handle unauthorized error
				if (error.response && error.response.status === 401) {
					logout();
				}
			}).finally((error) => {

				// Update metadata and display settings
				const responseMetadata = {
					NbFile: 3,
					NbTokens: 30000,
				};
				setMetadata(responseMetadata);
				setDisplayFilesInfos(false);

				setLoading(false);

			});
	}



	return (
		<div className="Chat">
			<header className="Chat-header">
				<div id="sidebar">

					<Spin spinning={loading} tip="Processing...">
						<div className='input_file_container'>
							<InputDropZone onFileSubmit={submitFilesAndProcess} displayFilesInfos={displayFilesInfos} setDisplayFilesInfos={setDisplayFilesInfos} />
						</div>
					</Spin>
					<div className='results'>

						{pdfUrl ? (
							<div className='pdf-viewer'>


								<Document file={pdfUrl.file} onLoadSuccess={onDocumentLoadSuccess}>
									{Array.from(new Array(numPages), (el, index) => (
										<Page key={`page_${index + 1}`} pageNumber={index + 1}
											renderTextLayer={false} // Disable text layer to further reduce spacing
											renderAnnotationLayer={false} // Disable annotations
											renderMode="canvas" // Use canvas rendering for better control
											width={450} // Set the width to the window width
										/>
									))}

									{/* <Page pageNumber={pageNumber}/> */}
								</Document>
								{/* <p>
                    Page {pageNumber} of {numPages}
                  </p> */}

							</div>
						) : (images && (
							<div className="container-image-viewer">
								{/* <img src={images.preview} alt={images.alt} /> */}
								<img src={images.preview} alt={images.alt} style={{ maxWidth: '100%', maxHeight: '100%' }} />
								{/* <img src={images[currentImageIndex]} alt="Displayed" style={{ maxWidth: '100%', maxHeight: '100%' }} /> */}
							</div>

						))
						}

					</div>

					<div>

					</div>
				</div>

				<div id="main_content">
					<ChatContainer messages={messages} onUpdateMessage={updateMessage} />

					<Spin spinning={loading} size='small' >
						<div className='input_src'>
							<InputField onSendMessage={sendMessage} />
						</div>
					</Spin>
				</div>

			</header>


		</div>
	);
};

export default Chat;


