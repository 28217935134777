import React, { useRef, useEffect } from 'react';
import MessageBubble from './MessageBubble';

const ChatContainer = ({ messages, onUpdateMessage }) => {



	return (
		<div>
			{messages.map((message, index) => {

				return <MessageBubble key={index} index={index} form_submitted={message.form_submitted} voted={message.voted} content={message.content} sender={message.sender} uuid={message.uuid} onUpdateMessage={onUpdateMessage} />
			}

			)}
		</div>
	);
};

export default ChatContainer;
